<template>
	<div>
		<a-spin :spinning="loading">
      <p style="color: red;">此配置用于云端系统出票座位，在开场前后自动释放座位。自动释放的座位不影响售后。</p>
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 250px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary" @click="onAdd">批量释放</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'createTime'">
              {{ transDateTime(record.createTime) }}
            </template>
					</template>
				</a-table>
			</div>
		</a-spin>
    <a-modal v-model:visible="showModal" title="批量释放座位" @ok="onBatchReleaseSeat" @cancel="showModal = false">
      <a-spin :spinning="loading">
        <a-form ref="batchForm" name="batchForm" :model="batchFormState">
          <a-form-item label="组织" name="organizationId" :rules="[{required: true, message: '请选择组织'}]">
            <a-select placeholder="请选择影院组织" v-model:value="batchFormState.organizationId" style="width: 250px;"
                      @change="getAllCinemaList">
              <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="cinemaId" label="影院" :rules="[{required: true, message: '请选择影院'}]">
            <a-select placeholder="请选择影院" v-model:value="batchFormState.cinemaId" style="width: 250px;">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
                {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="releaseDays" label="释放天数" extra="批量释放当天减输入天数的已售场次的前台座位信息；" :rules="[{required: true, message: '请输入释放天数'}]">
            <a-input-number  v-model:value="batchFormState.releaseDays"/>
          </a-form-item>
          <p v-show="batchFormState.releaseDays">当前输入释放天数为{{ batchFormState.releaseDays }}天，释放{{ countDay }}(包含)及之前已售场次的前台座位信息；</p>
          <div>
            <p>举例说明:</p>
            <p>释放天数为0，释放{{ today }}(包含)及之前已售场次的前台座位信息；</p>
            <p>释放天数为2，释放{{ twoDayAgo }}(包含)及之前已售场次的前台座位信息；</p>
          </div>
        </a-form>
      </a-spin>
    </a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
  import {
    getOrganizationList,
    getCinemaList,
    getReleaseSeatsList,
    batchReleaseSeat, getBatchReleaseRecord
  } from '@/service/modules/cinema.js';
  import {asyncBatchDisable} from "@/service/modules/coupon";
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					buyTicketMode: '',
					isDisabled: '',
					organizationId: 0,
				},
				searchData: {},
        batchFormState: {
          releaseDays: 0,
          cinemaId: 0,
          organizationId: 0,
        },
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
          title: '释放天数',
          dataIndex: 'operateParam',
        }, {
          title: '操作人',
          dataIndex: 'operateUser',
        }, {
          title: '操作时间',
          dataIndex: 'createTime',
        }],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0
			}
		},
    computed: {
      today() {
        return this.moment().format('YYYY.MM.DD')
      },
      twoDayAgo() {
        return this.moment().subtract().format('YYYY.MM.DD')
      },
      countDay() {
        return this.moment().subtract(this.batchFormState.releaseDays, 'days').format('YYYY.MM.DD')
      }
    },
		created() {
			this.getOrganizationList();
			this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					// this.getData();
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.buyTicketMode = this.searchData.buyTicketMode ? this.searchData.buyTicketMode : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let postData = {
							...this.modelRef
						}
						let ret = await updateFilmMode(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.modelRef = {};
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCancel() {
				this.$refs.addForm.resetFields();
				this.modelRef = {};
				this.showModal = false;
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getBatchReleaseRecord({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData,
            type: 4,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
					if (this.organizationList.length) {
						this.formState.organizationId = this.organizationList[0].id;
						this.batchFormState.organizationId = this.organizationList[0].id;
						this.getAllCinemaList(this.formState.organizationId);
					}
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
      async onBatchReleaseSeat() {
        this.$confirm({
          title: "提示",
          content: '执行批量释放操作',
          onOk: async ()=> {
            this.loading = true;
            try {
              const postData = {
                cinemaId: this.batchFormState.cinemaId,
                releaseDays: this.batchFormState.releaseDays,
              };
              if (postData.cinemaId == 11 || postData.cinemaId == 5) {
                // 减少无操作, 在上生产的时候记得移除
                this.loading = false;
                this.$message.error('不要动生产数据!!!!!!!')
                return
              }
              const ret = await batchReleaseSeat(postData);
              if (ret.code === 200) {
                this.$message.success('操作成功');
                this.showModal = false;
                this.getData();
              }
              this.loading = false;
            } catch(e) {
              console.log(e)
              this.loading = false;
            }
          }
        })
      },
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
